import React, { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import { images } from "./GalleryImages";
import './Gallery.css'
import axios from "axios";
import { RxCross1 } from "react-icons/rx";
import Modal from "./Modal";
// import InnerBanner from '../About/InnerBanner';

export default function Galleryy({ images }) {
  const slides = images.map(({ original }) => ({
    src: original,
  }));
  // const [data, setData] = useState([])


  // const [clickedImg, setClickedImg] = useState(null);
  // const [currentIndex, setCurrentIndex] = useState(null);


  // const handleClick = (item, index) => {
  //   setCurrentIndex(index);
  //   setClickedImg(`${URL}/images/` + item.image);
  // };
  // // console.log("CLICKed", clickedImg)
  // const handelRotationRight = () => {
  //   const totalLength = data.length;
  //   if (currentIndex + 1 >= totalLength) {
  //     setCurrentIndex(0);
  //     const newUrl = `${URL}/images/` + data[0].image;
  //     setClickedImg(newUrl);
  //     return;
  //   }
  //   const newIndex = currentIndex + 1;
  //   const newUrl = data.filter((item) => {
  //     return data.indexOf(item) === newIndex;
  //   });
  //   const newItem = `${URL}/images/` + newUrl[0].image;
  //   setClickedImg(newItem);
  //   setCurrentIndex(newIndex);
  // };

  // const handelRotationLeft = () => {
  //   const totalLength = data.length;
  //   if (currentIndex === 0) {
  //     setCurrentIndex(totalLength - 1);
  //     const newUrl = `${URL}/images/` + data[totalLength - 1].image;
  //     setClickedImg(newUrl);
  //     return;
  //   }
  //   const newIndex = currentIndex - 1;
  //   const newUrl = data.filter((item) => {
  //     return data.indexOf(item) === newIndex;
  //   });
  //   const newItem = `${URL}/images/` + newUrl[0].image;
  //   setClickedImg(newItem);
  //   setCurrentIndex(newIndex);
  // };


  const [index, setIndex] = useState(-1);
  // const URL = "http://localhost:4000/api/v1"
  // const URL2 = "http://kishkindaresort.com/"
  // const URL = "https://kishkindaresort.com/api/v1"


  // const [data, setData] = useState([])
  // const [data, setData] = useState([])
  // const [file, setFile] = useState(null)
  // const [active, setActive] = useState(false)
  const handleClick = (index, item) => setIndex(index);
  // const datas = image.map((item) => {
  //   const dat = `${URL2}/${images}/` + `${item.image}`
  //   setData(dat)
  // })
  // console.log("datas", data)
  // imagessss.push(image.map(image => {
  //   src: { `${URL2}/images/ .${image.image.toString()}` };
  //   original: { `${URL2}/images/ .${image.image.toString()}` };
  // }))
  // useEffect(() => {
  //   const fetchImages = async () => {
  //     try {
  //       const response = await axios.get(`${URL}/getImage`);
  //       setData(response.data);
  //     } catch (error) {
  //       console.error('Failed to fetch images: ', error);
  //     }
  //   };
  //   fetchImages()
  // }, []);


  // const handleClick1 = (image) => {
  //   setFile(image)
  //   setActive(true)
  // }
  return (
    <div className="gallery">

      <div className="gallery-container">
        <Gallery
          images={images}
          // src={image.image_url}
          onClick={handleClick}
          enableImageSelection={false}
        />
        <Lightbox
          slides={slides}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
        />
      </div>
    </div>
    //     <div className="relative md:px-[80px] md:py-[40px]">
    //       <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-col-4 gap-x-1 gap-y-1">
    //         {
    //           image.map((image) => (
    //             <div className="relative">
    //               <div>
    //                 <img src={`${URL}/images/` + image.image} alt={image.name} width={360} className="md:h-[180px] h-[170px] w-[360px] rounded-[2px] object-cover" />
    //               </div>
    //               {
    //                 image.tag2 ? (
    //                   <div className="flex gap-1 absolute bottom-[3px] left-1 text-[#FFFF00] text-[12px]">
    //                     <p className="bg-[#000000A6] px-[8px] text-center font-medium rounded-[3px]">{image.tag1}</p>
    //                     <p className="bg-[#000000A6] px-1 font-medium rounded-[3px]">{image.tag2}</p>
    //                   </div>
    //                 ) : (
    //                   <div className="flex gap-1 absolute bottom-[3px] left-1 text-[#FFFF00] text-[12px]">
    //                     <p className="bg-[#000000A6] px-[8px] text-center font-medium rounded-[3px]">{image.tag1}</p>
    //                     {/* <p className='text-[12px] w-full'><span className='font-semibold'>Tag2 :</span> {image.tag2}</p> */}
    //                   </div>
    //                 )
    //               }
    //               {/* <div className="flex gap-1 absolute bottom-[3px] left-1 text-[#FFFF00] text-[12px]">
    //                 <p className="bg-[#000000A6] px-[8px] text-center font-medium rounded-[3px]">{image.tag1}</p>
    //                 <p className="bg-[#000000A6] px-1 font-medium rounded-[3px]">{image.tag2}</p>
    //               </div> */}
    //             </div>
    //           ))
    //         }
    //       </div>
    //     </div>
    //     {/* {

    //       active === true && (
    //         <div className="overflow-hidden max-w-maxContent py-16">
    //           <div className="datasss absolute bg-[#000000] md:w-full h-screen top-[-350px] md:top-[-319px] md:px-[160px] py-16 opacity-100">
    //             <div className="relative">
    //               <div className="flex flex-col">
    //                 <div className=" absolute text-end xl:top-[-20px] right-[-0px] xl:right-[-140px] text-white cursor-pointer" onClick={() => setActive(false)}>
    //                   <RxCross1 size={30} />
    //                 </div>
    //                 <img src={`${URL2}/images/` + file} alt="imageNodeFount" width={900} className="xl:h-[500px]" />
    //               </div>
    //             </div></div>
    //         </div>
    //       )
    //     } */}

    //   </div>
    // </div >
    // <div className="md:px-[80px] md:py-[50px] py-1">
    //   <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-col-4 gap-x-1 gap-y-1">
    //     {data.map((item, index) => (
    //       <div key={index} className="relative">
    //         <img
    //           src={`${URL}/images/` + item.image}
    //           // alt={item.text}
    //           width={360} className="md:h-[180px] h-[170px] w-[360px] rounded-[2px] object-cover"
    //           onClick={() => handleClick(item, index)}
    //         />
    //         {
    //           item.tag2 ? (
    //             <div className="flex gap-1 absolute bottom-[3px] left-1 text-[#FFFF00] text-[12px]">
    //               <p className="bg-[#000000A6] px-[8px] text-center font-medium rounded-[3px]">{item.tag1}</p>
    //               <p className="bg-[#000000A6] px-1 font-medium rounded-[3px]">{item.tag2}</p>
    //             </div>
    //           ) : (
    //             <div className="flex gap-1 absolute bottom-[3px] left-1 text-[#FFFF00] text-[12px]">
    //               <p className="bg-[#000000A6] px-[8px] text-center font-medium rounded-[3px]">{item.tag1}</p>
    //               {/* <p className='text-[12px] w-full'><span className='font-semibold'>Tag2 :</span> {image.tag2}</p> */}
    //             </div>
    //           )
    //         }
    //       </div>
    //     ))}
    //     <div className="bg-[#000000A6] z-10">
    //       {clickedImg && (
    //         <Modal
    //           clickedImg={clickedImg}
    //           handelRotationRight={handelRotationRight}
    //           setClickedImg={setClickedImg}
    //           handelRotationLeft={handelRotationLeft}
    //         />
    //       )}
    //     </div>

  );
}
