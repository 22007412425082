import React, { useState } from 'react'
// import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
// import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Loginn } from '../../redux/operation/authApi'
// import toast from 'react-hot-toast'
import Tilt from 'react-parallax-tilt'
import toast from 'react-hot-toast'
// import axios from 'axios'


const Login = () => {
    const dispatch = useDispatch()
    // const URL = "http://kishkindaresort.com/api/v1"
    const [clicked, setClicked] = useState(false)
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: "", password: ""
    })
    const { email, password } = formData
    // const [visible, setVisible] = useState(true) const handleOnChange = (e) => {
    const handleOnChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!clicked) {
            dispatch(Loginn(email, password, navigate))
            setFormData({
                email: "", password: ""
            })
            setClicked(true)
        } else {
            dispatch(Loginn(email, password, navigate))  
        }
    }
    return (
        <div className="App bg-gray-700 h-screen w-screen relative overflow-hidden flex justify-center items-center">
            <div className="h-40-r w-40-r bg-gradient-to-r from-green-400 to-blue-500 rounded-full absolute left-2/3 -top-56 transform rotate-160 animate-pulse"></div>
            <div className="h-35-r w-35-r bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full absolute top-96 -left-20 transform rotate-180 animate-pulse"></div>
            <Tilt>
                <div className="container h-96 w-96 bg-white bg-opacity-10 rounded-2xl shadow-5xl relative z-2 border-opacity-80 border-2 border-r-0 border-b-0 border-white backdrop-filter backdrop-blur-sm">
                    <form className='h-full flex flex-col justify-evenly items-center' onSubmit={handleSubmit}>
                        <div className=' font-poppins text-3xl font-semibold tracking-widest'>Admin Panel</div>
                        <input type="text" placeholder='Email id' name='email' value={email} onChange={handleOnChange}
                            className=' border border-t-0 border-l-0 border-grap-900 border-r-0 focus:outline-none text-black tracking-wide w-[90%] border-white px-4 py-2 rounded-lg text-[17px]' />
                        <input type="password" placeholder='password' name='password' value={password} onChange={handleOnChange}
                            className='border border-t-0 border-l-0 border-r-0  border-gray-900 focus:outline-none text-black  tracking-wide w-[90%] px-4 py-2 rounded-lg text-[17px]' />
                        {/* <input type="Submit" className='cursor-pointer font-poppins rounded-full px-5 py-1 bg-white hover:bg-white hover:bg-opacity-80 ' /> */}
                        <button type='submit' disabled={clicked} className='cursor-pointer font-poppins rounded-full px-5 py-1 bg-white hover:bg-white hover:bg-opacity-80'>Submit</button>
                    </form>
                </div>
            </Tilt>
            <div>
            </div>
        </div>
    )
}

export default Login