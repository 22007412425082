import React, { useState, useEffect } from "react";
import { Button, Dropdown, Row, Col } from "react-bootstrap";
// Import DateRangePicker and styles
// import { DateRangePicker } from "react-dates";
// import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";
import "./NavbarBelow.css";

const NavbarBelow = () => {
  // const calicon=<i class="fa-solid fa-calendar-days"/>;
  // const [dateRange, setDateRange] = useState({
  //   startDate: null,
  //   endDate: null,
  // });
  // const [focusedInput, setFocusedInput] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  // Check if screen size is less than 1024px
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    // Initial check
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Commented out date range-related code
  // const today = new Date();
  // const nextDay = new Date(today);
  // nextDay.setDate(today.getDate() + 1);

  // const numberOfNights =
  //   dateRange.startDate && dateRange.endDate
  //     ? Math.ceil(
  //         (dateRange.endDate - dateRange.startDate) / (1000 * 60 * 60 * 24)
  //       )
  //     : 0;

  // const placeholderText =
  //   numberOfNights > 0
  //     ? `Check-in: ${today.toLocaleDateString("en-US", {
  //         month: "short",
  //         day: "numeric",
  //         year: "numeric",
  //       })} - ${numberOfNights} night${numberOfNights !== 1 ? "s" : ""}`
  //     : `Check-in: ${today.toLocaleDateString("en-US", {
  //         month: "short",
  //         day: "numeric",
  //         year: "numeric",
  //       })}`;

  return (
    <div className="navbar-below-container z-3">
      <Row>
        <Col md={3}>
          <Dropdown onSelect={() => { }}>
            <Dropdown.Toggle className="hotel-dropdown" id="dropdown-basic">
              Kishkinda Heritage Resort
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Kishkinda Heritage Resort</Dropdown.Item>
              <Dropdown.Item>Kishkinda Water Park</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col md={isMobile ? 12 : 7}>
          {/* Commented out DateRangePicker */}
          {/* <DateRangePicker
            startDate={dateRange.startDate}
            startDateId="start_date_id"
            endDate={dateRange.endDate}
            endDateId="end_date_id"
            onDatesChange={({ startDate, endDate }) =>
              setDateRange({ startDate, endDate })
            }
            focusedInput={focusedInput}
            onFocusChange={(focused) => setFocusedInput(focused)}
            displayFormat="MMM D, YYYY"
            startDatePlaceholderText={isMobile ? "Check-In" : placeholderText}
            endDatePlaceholderText={
              isMobile
                ? "Check-Out"
                : `Check-out: ${nextDay.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}`
            }
            isOutsideRange={() => false}
            style={{ width: isMobile ? "calc(100% - 40px)" : "100%" }}
          /> */}
        </Col>
        {/* <Col md={1}></Col> */}

        <Col md={2}>
          <a href="https://hotels.eglobe-solutions.com/kishkindaheritage/booking/hotels/kishkinda-heritage-resort-hampi" target="_blank">
            <Button className="book-now-button">
              Book Now
            </Button></a>
        </Col>
      </Row>
    </div>
  );
};

export default NavbarBelow;
