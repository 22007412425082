import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { announcements, discounts } from "./Announcement_N_Offers_Data"; // Import the arrays from the separate file
import "./Announcement_N_Offers.css"; // Import a separate CSS file for styling
import "./Heading2.css";
import axios from 'axios';
import { getAnnoucement, getOffer } from "../../redux/operation/dataApi";
import { BallTriangle } from "react-loader-spinner";
// import { getAnnoucement } from "../../../../backend/controllers/annoucement";

const Announcement_N_Offers = () => {
  // Sample data for announcements and discounts
  const [annoucements, setAnnoucements] = useState([])
  const [discounts, setDiscounts] = useState([])
  const [loading, setLoading] = useState(true)
  // const URL = "http://localhost:4000/api/v1"
  const URL = "http://kishkindaresort.com/api/v1"
  useEffect(() => {
    const fetchData1 = async () => {
      // const res = await axios.get(`${URL}/getAnnoucement`)
      // if (res) {
      //   setAnnoucements(res.data.data)
      //   setLoading(false)
      // }
      const res = await getAnnoucement()
      if (res) {
        setLoading(false)
        setAnnoucements(res)
      }
    }
    const fetchData2 = async () => {
      // const res = await axios.get(`${URL}/getOffer`)
      // if (res) {
      //   setDiscounts(res.data.data)
      //   setLoading(false)
      // }
      const res = await getOffer()
      if (res) {
        setLoading(false)
        setDiscounts(res)
      }
    }
    fetchData1()
    fetchData2()
  }, [])

  // console.log("annoucement", annoucements)
  // console.log("discount", discounts)
  // Function to render announcement cards
  const renderAnnouncementCards = () => {
    return annoucements.map((announcement) => (
      <Card key={announcement.id} className="mb-2 cardcolor">
        <Card.Body>
          <Card.Title>{announcement.heading}</Card.Title>
          <Card.Text>{announcement.description}</Card.Text>
        </Card.Body>
      </Card>
    ));
  };

  // Function to render discount cards
  const renderDiscountCards = () => {
    return discounts.map((discount) => (
      <Card key={discount.id} className="mb-2 cardcolor">
        <Card.Body>
          <Card.Title>{discount.heading}</Card.Title>
          <Card.Text>{discount.desc}</Card.Text>
          {/* <Button className="discount-btn">{discount.claim}</Button> */}
          <a href="https://hotels.eglobe-solutions.com/kishkindaheritage/booking/hotels/kishkinda-heritage-resort-hampi" target="_blank">
            <Button className="discount-btn">Claim Discount</Button></a>
        </Card.Body>
        {/* <Card.Footer>
          <Button variant="dark">Claim Discount</Button>
        </Card.Footer> */}
      </Card>
    ));
  };

  return (
    <div className="containerparent">
      <Container
        fluid
        className="my-0  custom-container Announcement_N_Offers_CSS"
      >
        <Row className="roww">
          {/* Announcements Container */}
          {
            loading ? (
              <div className="flex justify-center items-center gap-3 px-3 py-3 z-5">
                <p className="text-xl font-semibold">Annoucement loading......</p>
                <BallTriangle
                  height={100}
                  width={100}
                  radius={5}
                  color="#55b7d2"
                  ariaLabel="ball-triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  className='text-center mx-auto'
                />

              </div>
            ) : (
              <Col md={5} className="custom-scroll columnn">
                <div className="popularr topp">
                  <div className="full_container">
                    <div className="fixed-heading">
                      <div className="headingg">
                        <h2 className="text-center mb-4 text-3xl">Announcements</h2>
                        <div className="linee"></div>
                      </div>
                    </div>
                    <div className="contentt carousel-scroll">{renderAnnouncementCards()}</div>
                  </div>
                </div>
              </Col>
            )
          }

          {/* Discounts Container */}
          {
            loading ? (
              <div className="flex justify-center items-center gap-3 px-3 py-3 z-5">
                <p className="text-xl font-semibold">Annoucement loading......</p>
                <BallTriangle
                  height={100}
                  width={100}
                  radius={5}
                  color="#55b7d2"
                  ariaLabel="ball-triangle-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  className='text-center mx-auto'
                />

              </div>
            ) : (
              <Col md={5} className="custom-scroll columnn">
                <section className="popularr topp">
                  <div className="full_container">
                    <div className="fixed-heading">
                      <div className="headingg">
                        <h2 className="text-center mb-4 text-3xl">Discount Offers</h2>
                        <div className="linee"></div>
                      </div>
                    </div>
                    <div className="contentt carousel-scroll">{renderDiscountCards()}</div>
                  </div>
                </section>
              </Col>
            )
          }
        </Row>
      </Container>
    </div>
  );
};

export default Announcement_N_Offers;
