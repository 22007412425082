import React from "react";
import { Container } from "react-bootstrap";
import Galleryy from "../Gallery/Gallery";
// import { Link } from "react-router-dom";
// import './OtherRooms.css'

const ImageGallery = ({ images }) => {
  // Filter out the current room from the data
  //   const otherRoomsData = Sdata.filter((room) => room.id !== currentRoom.id);

  return (
    <Container className="my-5 imagegallery">
      <h2 className="text-center font-medium text-2xl">Room Images</h2>
      <Galleryy images={images} />
    </Container>
  );
};

export default ImageGallery;
