import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import classNames from 'classnames';
import "./RoomsType.css";
import styles from "./Heading.module.css"

const RoomsType = () => {
  const roomData = [
    {
      id: 1,
      type: "Non AC Cottage",
      image: "Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC5.jpg",
      detailsLink: "/room/non-ac-cottage-kiskinda-heritage-resort",
    },
    {
      id: 2,
      type: "AC Cottage",
      image: "Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC6.jpg",
      detailsLink: "/room/ac-cottage-kiskinda-heritage-resort",
    },
    {
      id: 3,
      type: "AC Suite Room",
      image: "Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS7.jpg",
      detailsLink: "/room/ac-suite-room-kiskinda-heritage-resort",
    },
    {
      id: 4,
      type: "AC Executive Room",
      soon: "Coming Soon...",
      image: "Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS4.jpg",
      // detailsLink: "/room/ac-executive-room-kiskinda-heritage-resort",
    },
  ];

  const renderRoomCards = () => {
    return roomData.map((room) => (
      <Col key={room.id} md={6} lg={6} xl={6} className="mb-4">
        <Card className="room-card">
          <Card.Img variant="top" src={room.image} className="room-image" />
          <Card.Body className="text-overlay">
            <Card.Title>{room.type}</Card.Title>
            {
              room.soon ? <Card.Title>{room.soon}</Card.Title> : ""
            }
            {/* Use Link with Button style for navigation */}
            {
              room.detailsLink ? <Link to={room.detailsLink} className="btn details-button">
                More Details
              </Link>:""
           }
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <>
      <section className={classNames(styles.popular, styles.top)}>
        <div className={styles.full_container}>
          <div className={styles.heading}>
            <h1>Our Rooms</h1>
            <div className={styles.line}></div>
          </div>

          <div className={styles.content}>
            <Container className="my-5 roomstypecards">
              <Row>{renderRoomCards()}</Row>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoomsType;
