import toast from 'react-hot-toast'
import { apiConnector } from '../apiConnector'
import { setToken, setLoading } from '../slice/authSlice'



// export function login(email, password, navigate) {
//     // const URL = "https://athnum.com/api/v1/login"
//     // const URL = "http://localhost:4000/api/v1/login"
//     const URL ="https://kishkindaresort.com/api/v1/login"
//     return async (dispatch) => {
//         try {
//             const response = await apiConnector("POST", URL, {
//                 email,
//                 password,
//             })

//             // console.log("LOGIN API RESPONSE............", response)

//             if (!response.data.success) {
//                 throw new Error(response.data.message)
//             }

//             // toast.success("Login Successful")
//             dispatch(setToken(response.data.token))

//             localStorage.setItem("token", JSON.stringify(response.data.token))
//             localStorage.setItem("user", JSON.stringify(response.data.user))
//             navigate("/admin")
//             toast.success("Login Successfully")
//         } catch (error) {
//             console.log("LOGIN API ERROR............", error)
//             toast.error("Error while login")
//         }
//     }
// 
export function Loginn(email, password, navigate) {
    const URL = "https://kishkindaresort.com/api/v1/login"
    return async (dispatch) => {
        const toastId = toast.loading("Loading...")
        dispatch(setLoading(true))
        try {
            const response = await apiConnector("POST", URL, {
                email,
                password,
            })
            if (!response.data.success) {
                throw new Error(response.data.message)
            }
            navigate("/admin")
            dispatch(setToken(response.data.token))
            localStorage.setItem("token", JSON.stringify(response.data.token))
            localStorage.setItem("user", JSON.stringify(response.data.user))
            toast.success("Login Successful")
        } catch (error) {
            console.log("LOGIN API ERROR............", error)
            toast.error("Login Failed")
        }
        dispatch(setLoading(false))
        toast.dismiss(toastId)
    }
}

export function logout(navigate) {
    return (dispatch) => {
        dispatch(setToken(null))
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        toast.success("Logged Out")
        navigate("/")
    }
}