import React from 'react';
import './App.css';
// import PatternSVG from './PatternSVG';
// import 'react-dates/lib/css/_datepicker.css'; // Import the styles for react-dates
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
// import Slider from 'react-slick'
// import {images} from './Components/Gallery/GalleryImages';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SinglePage from "./Components/SinglePage/SinglePage"
import MyNavbar from './Common/Navbar/Navbar';
import NavbarBelow from './Common/Navbar/NavbarBelow';
import Footer from './Common/Footer/Footer';
import { Home } from './Components/Home/Home';
import About from './Components/About/About';
// import  Gallery  from './Components/Gallery/Gallery';
import GalleryPage from './Components/Gallery1/GalleryPage';
import Contact from './Components/Contact/Contact';
// import { Overview } from './Components/Overview/Overview';
import Rooms from './Components/Rooms/Rooms';
import Facilities from './Components/Facilities/Facilities';
// import { Book } from './Components/Book/Book';
// import SignInPage from './Components/SigninJoin/SignInPage';
// import JoinPage from './Components/SigninJoin/JoinPage';
import PrivacyPolicy from './Components/Policies/PrivacyPolicy';
import CancellationPolicy from './Components/Policies/CancellationPolicy';
import GeneralHotelPolicy from './Components/Policies/GeneralHotelPolicy';
import WhatsAppChat from './Components/WhatsApp/WhatsAppChat';
import TawkToScript from './Components/Tawk/TawkTo';
import Login from './Components/login/Login'
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import Admin from './Components/AdminPanel/Admin/Admin';
import { Navbar } from 'react-bootstrap';
import ThankYou from './Components/Thankyou/ThankYou';


function App() {
  const location = useLocation();
  const hideNavbarFooter = location.pathname === '/thankyou';
  return (
    <>
      {!hideNavbarFooter && <Navbars />}
      
      {/* <NavbarBelow /> */}
      {/* <PatternSVG /> */}
      {/* <Routes> */}
      <Routes>
      <Route path='/thankyou' element={<ThankYou />} />
        <Route path='/login' element={<Login />} />
        <Route path='/admin' element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>} />
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/gallery' element={<GalleryPage />} />
        {/* <Route exact path='/overview' element={<Overview />} /> */}
        <Route exact path='/rooms' element={<Rooms />} />
        <Route exact path='/room/:title' element={<SinglePage />} />
        <Route exact path='/facilities' element={<Facilities />} />
        {/* <Route exact path='/book' element={<Book />} /> */}
        <Route exact path='/contact' element={<Contact />} />
        {/* <Route exact path='/signin' element={<SignInPage />} />
          <Route exact path='/join' element={<JoinPage />} /> */}
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/cancellation-policy' element={<CancellationPolicy />} />
        <Route exact path='/general-hotel-policy' element={<GeneralHotelPolicy />} />
      </Routes>
    {!hideNavbarFooter && <WhatsAppChatcust />}
      {/* <TawkToScriptcust /> */}
      {!hideNavbarFooter && <Footercust />}
      {/* </Routes> */}
    </>
  );
}

function Navbars() {
  const location = useLocation();
  const isAdmin = location.pathname.startsWith('/login') || location.pathname.startsWith('/admin');
  return isAdmin ? "" : <MyNavbar />
}

function WhatsAppChatcust() {
  const location = useLocation();
  const isAdmin = location.pathname.startsWith('/login') || location.pathname.startsWith('/admin');
  return isAdmin ? "" : <WhatsAppChat />
}

function TawkToScriptcust() {
  const location = useLocation();
  const isAdmin = location.pathname.startsWith('/login') || location.pathname.startsWith('/admin');
  return isAdmin ? "" : <TawkToScript />
}

function Footercust() {
  const location = useLocation();
  const isAdmin = location.pathname.startsWith('/login') || location.pathname.startsWith('/admin');
  return isAdmin ? "" : <Footer />
}

export default App;
