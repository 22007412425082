  import React, { useState, useEffect } from "react";
import { Card, Carousel } from "react-bootstrap";
import "./Testimonials.css";

const Testimonials = ({ testimonials }) => {
  const [cardsPerChunk, setCardsPerChunk] = useState(3);

  const calculateCardsPerChunk = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 3; // Show 3 cards per chunk for large screens
    } else if (screenWidth >= 768) {
      return 2; // Show 2 cards per chunk for medium screens
    } else {
      return 1; // Show 1 card per chunk for small screens
    }
  };


  const handleResize = () => {
    const newCardsPerChunk = calculateCardsPerChunk();
    setCardsPerChunk(newCardsPerChunk);
  };

  useEffect(() => {
    handleResize(); // Initial calculation

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderTestimonials = () => {
    const chunks = testimonials.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / cardsPerChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

    return chunks.map((chunk, index) => (
      <Carousel.Item key={index}>
        <div className="d-flex">
          {chunk.map((testimonial, innerIndex) => (
            <Card key={innerIndex} className="testimonial-card">
              <Card.Body>
                <div className="testimonial-flex">
                  <div className="customerIcon flex items-center justify-center">
                    <img
                      src={testimonial.image}
                      alt={`Customer ${index * cardsPerChunk + innerIndex + 1}`}
                    // className="text-center"
                    />
                  </div>
                  <div className="customer-info">
                    <h6>{testimonial.name}</h6>
                    <div className="star-ratings">
                      <span>{`Ratings: ${testimonial.rating}/5 star`}</span>
                    </div>
                  </div>
                </div>
                <p>{testimonial.review}</p>
                <span>{testimonial.date}</span>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Carousel.Item>
    ));
  };

  return (
    <section className="pt-30 pb-20 bg_light_blue">
      <div className="container">
        <h2 className="custom_title text-uppercase text-center">
          <strong>Testimonials</strong>
        </h2>
        <Carousel interval={3000} pause="hover">
          {renderTestimonials()}
        </Carousel>
      </div>
    </section>
  );
};

export default Testimonials;
