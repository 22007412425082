import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { IoAddCircleSharp } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { deleteAnnoucement, getAllNatureOfBusiness, getAnnoucement } from '../../../redux/operation/dataApi';



export default function Annoucemnt() {

    const { token } = useSelector((state) => state.auth)
    const [click, setClick] = useState(false)
    const [nature, setNature] = useState({
        heading: "", description: ""
    })
    const [data, setData] = useState([])
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(false)
    const [delet, setDelet] = useState('')
    const URL = "https://kishkindaresort.com/api/v1"
    // const URL = "http://localhost:4000/api/v1"
    useEffect(() => {
        const fetchData = async () => {
            const res = await getAnnoucement(token)
            if (res) {
                setData(res)
                setLoading(true)
            }
        }
        fetchData()
    }, [loading])
    const handleChange = (e) => {
        setNature((prev) => ({
            ...prev, [e.target.name]: e.target.value
        }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const res = await axios.post(`${URL}/annoucement`, nature, {

        })
        if (res) {
            toast.success("Add Successfully")
            setNature({
                heading: "",
                description: ""
            })
            setLoading(!loading)
        } else {
            toast.error("Error")
        }

    }

    const handleClick = async (id) => {
        setDelet(id)
        setActive(!active)
        // await deleteAnnoucement(id, token)

    }

    const handleClick1 = async () => {
        await deleteAnnoucement(delet, token)

        setActive(false)
        setLoading(!loading)
        // setData(res)

    }



    return (
        <div className='max-h-max w-full'>

            <button onClick={() => setClick(!click)} className='ml-4 xl:ml-0 bg-[#F9CCD3] px-3 py-3 flex'>
                <IoAddCircleSharp size={25} className='mr-2' />Add Annoucements
            </button>
            <div>
                {
                    click === true && (
                        <div className='mt-3 bg-white'>
                            <div className='px-3 py-2'>
                                <form action="" className='' onSubmit={handleSubmit}>
                                    <label htmlFor="" className='w-full'>
                                        <p className=''> Heading</p>
                                        <input type="text" placeholder='Enter heading' name='heading' value={nature.heading} onChange={handleChange}
                                            className='xl:px-2 px-[8px] xl:mt-2 py-[6px] border w-[100%] md:w-[50%]' />
                                    </label>
                                    <label htmlFor="" className='w-full'>
                                        <p className='mt-6'>Description</p>
                                        <textarea id="" cols="30" rows="2" placeholder='Enter description' name='description' value={nature.description} onChange={handleChange}
                                            className='border px-2 py-2 w-[100%] md:w-[50%]'></textarea>
                                    </label>
                                    <button type='submit' className='ml-0 xl:ml-0 xl:px-[30px] xl:py-[8px] px-[10px] py-[4px] bg-[#D10000] text-white mt-4 h-full'
                                    >Create</button>
                                </form>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="p-3 mr-10 relative" >
                <h1 className="text-2xl mb-4">Annoucement</h1>
                <div className="overflow-auto rounded-lg shadow hidden md:block mt-4">
                    <table className='w-full'>
                        <thead className='bg-gray-50 border-b-2 border-gray-200'>
                            <tr className='flex gap-[50px] w-full'>
                                {/* <th className='w-[2px] py-3 px-2 text-[18px] font-semibold tracking-wide text-left'>Id</th> */}
                                <th className=' p-3 text-[18px] w-[20%] font-semibold tracking-wide text-left'>Heading</th>
                                <th className=' p-3 text-[18px] w-[32%] font-semibold tracking-wide text-left'>Description</th>
                                <th className=' p-3 text-[18px] ml-20 font-semibold tracking-wide text-left'>Delete</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-100">
                            {
                                data && data.map((item) => (
                                    <tr className='flex gap-[50px] mt-1' key={item.id}>
                                        {/* <td className="p-2 text-md text-gray-700 whitespace-nowrap">{item.id}</td> */}
                                        <td className="p-2 w-[20%] text-wrap text-md text-gray-700 whitespace-nowrap">{item.heading}</td>
                                        <td className="p-2 w-[41%] text-wrap text-md text-gray-700 whitespace-nowrap">{item.description}</td>
                                        <td className="p-2 w-[7%] text-md text-gray-700 whitespace-nowrap">
                                            <button onClick={() => handleClick(item.id)}>Delete</button>
                                        </td>
                                    </tr>

                                ))
                            }

                        </tbody>
                    </table>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden w-[135%] max-w-maxContent">
                    {
                        data && data.map((data) => (
                            <div className="bg-white space-y-1 p-4 rounded-lg shadow" key={data.id}>
                                <div className="flex items-start space-x-2 text-sm justify-between">
                                    {/* <div>
                                        <a href="#" className="text-blue-500 font-bold hover:underline">{data.id}</a>
                                    </div> */}
                                    <div className="text-gray-500 text-wrap">Heading : &nbsp;{data.heading}</div>
                                    {/* <div>
                                        <Link to={`/member/${data.id}`}> <span
                                            className="p-1.5 w-full max-w-max text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">Detail</span></Link>
                                    </div> */}
                                </div>
                                <div className="text-sm text-gray-700 text-wrap">
                                    Descrption :  &nbsp;
                                    {data.description}
                                </div>
                                <div className="text-sm text-white text-center py-2 px-2 bg-red-700 w-[30%]">
                                    <button onClick={() => handleClick(data.id)}> Delete</button>
                                </div>
                            </div>
                        ))
                    }

                </div>
                {
                    active === true ? (
                        <div className='bg-white top-[200px] absolute bottom-[20px] md:right-[350px] h-[30vh] flex flex-col gap-10 justify-center md:w-[30vw] w-[40vh] items-center'>
                            <div>
                                <div className='text-xl'>Are You Sure to delete?</div>
                            </div>
                            <div className='flex justify-between gap-24'>
                                <button className='px-4 py-2 bg-red-700 text-white' onClick={handleClick1}>Delete</button>
                                <button className='py-2 px-4 bg-blue-800 text-white' onClick={() => setActive(false)}>Close</button>
                            </div>
                        </div>
                    ) : ""
                }
            </div>

        </div>
    )
}