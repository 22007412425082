import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHospital, faUtensils, faSwimmingPool, faBirthdayCake, faGlassCheers, faBus, 
  faBuilding, faCoffee, faTshirt, faUserTie, faConciergeBell, faMapMarkedAlt, 
  faCarAlt, faSmoking, faCheck, faPager, faTv, faUsers, faMoneyBillWave, faInfo, 
  faCar, faDollarSign
} from '@fortawesome/free-solid-svg-icons';
import './Facilities.css';
import InnerBanner from '../About/InnerBanner';


const Facilities = () => {
  const facilitiesData = [
    { title: 'Medical', icon: faHospital },
    { title: 'Fine Dining', icon: faUtensils },
    { title: 'Swimming Pool', icon: faSwimmingPool },
    { title: 'Banquet', icon: faBirthdayCake },
    { title: 'Bar', icon: faGlassCheers },
    { title: 'Bus Parking', icon: faBus },
    { title: 'Business Center', icon: faBuilding },
    { title: 'Coffee Shop', icon: faCoffee },
    { title: 'Laundry', icon: faTshirt },
    { title: 'Concierge', icon: faUserTie },
    { title: 'Travel Desk', icon: faMapMarkedAlt },
    { title: 'Free Parking', icon: faCarAlt },
    { title: 'Non Smoking', icon: faSmoking },
    { title: 'Check-Out', icon: faCheck },
    { title: 'Outdoor Pool', icon: faSwimmingPool },
    { title: 'Parking', icon: faCar },
    { title: 'Restaurant', icon: faUtensils },
    { title: 'Room Service', icon: faPager },
    { title: 'Satellite TV', icon: faTv },
    { title: 'Concierge Desk', icon: faConciergeBell },
    { title: 'Outdoor Parking', icon: faCar },
    { title: 'Currency Exchange', icon: faDollarSign },
    { title: 'Front Desk', icon: faBuilding },
    { title: 'Indoor Parking', icon: faCar },
  ];

  return (
    <div>
      <InnerBanner heading='Facilities'/>
    <Container className="facilities-container mt-5">
      <Row>
        {facilitiesData.map((facility, index) => (
          <Col key={index} xs={6} sm={4} md={3} lg={2} className="facility-col">
            <Card className="facility-card">
              <FontAwesomeIcon icon={facility.icon} className="facility-icon" />
              <Card.Body>
                <Card.Title className="facility-title">{facility.title}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </div>
  );
};

export default Facilities;
