import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sdata from "./Sdata";
import EmptyFile from "../../Common/Empty/EmptyFile";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./SinglePage.css";
import OtherRooms from "./OtherRooms";
import ImageGallery from "./ImageGallery";

const SinglePage = () => {
  const { title } = useParams();
  const [item, setItem] = useState(null);

  useEffect(() => {
    const roomTitleFromParams = title.replace(/-kiskinda-heritage-resort$/, "");
    const roomTitleWithSpace = roomTitleFromParams.replace(/-/g, " ");
    const roomItem = Sdata.find(
      (item) => item.title.toLowerCase() === roomTitleWithSpace
    );

    if (roomItem) {
      setItem(roomItem);
    }
  }, [title]);

  return (
    <>
      {item ? (
        <section className="single-page">
          <Link to="/rooms" className="primary-btn back">
            <i className="fas fa-long-arrow-alt-left"></i> Go Back
          </Link>

          <Container className="my-5">
            <h1 className="room-page-heading">
              {item.title} at Kishkinda Heritage Resort
            </h1>
            <Card className="room-room-card mt-4">
              <Row>
                <Col md={6} className="roomimage">
                  <Card.Img
                    src={item.image}
                    alt={item.title}
                    className="img-fluid room-room-image"
                  />
                </Col>
                <Col md={6} className="room-room-details">
                  <Card.Body>
                    <h3 className="room-room-subheading mt-0">{item.desc}</h3>
                    <p className="room-room-description">{item.sidepara}</p>
                    <div className="price-n-button">
                      {item.id !== 4 && (
                        <p className="font-weight-bold room-room-price">
                          INR <strong className="price">{item.price}</strong>{" "}
                          per night
                        </p>
                      )}
                      {item.id !== 4 && (
                        <a href="https://hotels.eglobe-solutions.com/kishkindaheritage/booking/hotels/kishkinda-heritage-resort-hampi" target="_blank">
                          <Button className="primary-btn book-now-btn">
                            Book Now
                          </Button></a>
                      )}
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
            {item.id !== 4 && <div className="otherdetails">
              <h6>
                <i className="fas fa-long-arrow-alt-right"></i> Extra Bed
                Adult/Child(6-12 Years) INR 1500/-
              </h6>
              <h6>
                <i className="fas fa-long-arrow-alt-right"></i> +12% GST- T&C apply.
              </h6>
              <h6>
                <i className="fas fa-long-arrow-alt-right"></i> Check In 9.00 AM.
              </h6>
              <h6>* These rates are not valid on Holidays.</h6>
              <h6>* Discount offers not applicable on Holidays.</h6>
            </div>}
          </Container>
          {item.id !== 4 && <ImageGallery images={item.images} />}
          <OtherRooms currentRoom={item} />
        </section>
      ) : (
        <EmptyFile />
      )}
    </>
  );
};

export default SinglePage;
