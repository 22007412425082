import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { IoAddCircleSharp } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { deleteDiscount, getOffer } from '../../../redux/operation/dataApi';

const Discount = () => {
    const dispatch = useDispatch()
    const { token } = useSelector((state) => state.auth)
    const [click, setClick] = useState(false)
    const [nature, setNature] = useState({
        heading: "", desc: ""
    })
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    // const URL = "http://kishkindaresort.com/api/v1"
    const URL = "https://kishkindaresort.com/api/v1"
    const [active, setActive] = useState(false)
    const [delet, setDelet] = useState('')
    // const URL = "http://localhost:4000/api/v1"
    useEffect(() => {
        const fetchData = async () => {
            const res = await getOffer(token)
            if (res) {
                setData(res)
                setLoading(true)
            }
        }
        fetchData()

    }, [loading])
    const handleChange = (e) => {
        setNature((prev) => ({
            ...prev, [e.target.name]: e.target.value
        }))
    }
    // const [delet, setDelet] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
        const res = await axios.post(`${URL}/offer`, nature)
        if (res) {
            toast.success("Add Successfully")
            setNature({
                heading: "", desc: ""
            })
            setLoading(!loading)
        } else {
            toast.error("Error")
        }

    }

    const handleClick = async (id) => {
        setDelet(id)
        setActive(!active)
    }
    const handleClick1 = async () => {
        await deleteDiscount(delet, token)
        setActive(false)
        setLoading(!loading)
    }
    return (
        <div className='max-h-max relative'>

            <button onClick={() => setClick(!click)} className='ml-4 xl:ml-0 bg-[#F9CCD3] px-3 py-3 flex'>
                <IoAddCircleSharp size={25} className='mr-2' />Add Offers
            </button>
            <div>
                {
                    click === true && (
                        <div className='mt-3 bg-white'>
                            <div className='px-3 py-2'>
                                <form action="" className='' onSubmit={handleSubmit}>
                                    <label htmlFor="" className='w-full'>
                                        <p>Heading</p>
                                        <input type="text" placeholder='Enter heading' name='heading' value={nature.heading} onChange={handleChange}
                                            className='xl:px-2 px-[8px] py-[5px] border md:w-[50%] w-[100%]' />
                                    </label>
                                    <label htmlFor="" className='w-full mt-2'>
                                        <p>Description</p>
                                        <textarea id="" cols="30" rows="2" placeholder='Enter description' name="desc" value={nature.desc} onChange={handleChange}
                                            className='border xl:px-2 px-[8px] py-[6px] md:w-[50%] w-[100%]'></textarea>
                                    </label>
                                    {/* <label htmlFor="" className='w-full'>
                                        <p>Claim URL</p>
                                        <input type="text" placeholder='Claim' name='claim' value={nature.claim} onChange={handleChange}
                                            className='xl:px-10 px-[8px] py-[6px] border md:w-[50%] w-[100%]' />
                                    </label> */}
                                    <button type='submit' className='xl:px-[30px] xl:py-[8px] px-[10px] py-[4px] bg-[#D10000] text-white mt-3 h-full'
                                    >Create</button>
                                </form>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="p-3 mr-10">
                <h1 className="text-2xl mb-4">Offers</h1>
                <div className="overflow-auto rounded-lg shadow hidden md:block mt-4 ">
                    <table className='w-full'>
                        <thead className='bg-gray-50 border-b-2 border-gray-200'>
                            <tr className='flex gap-[50px] '>
                                {/* <th className='w-[2px] py-3 px-2 text-[18px] font-semibold tracking-wide text-left'>Id</th> */}
                                <th className=' p-3 w-[5%] text-[18px] font-semibold tracking-wide text-left'>Heading</th>
                                <th className=' p-3 text-[18px] w-[60%] ml-20 font-semibold tracking-wide text-left'>Description</th>
                                {/* <th className=' p-3 text-[18px] w-[20%] font-semibold tracking-wide text-left'>Claim</th> */}
                                <th className=' p-3 text-[18px] w-[10%] font-semibold tracking-wide text-left'>Delete</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-100 w-full">
                            {
                                data && data.map((item) => (
                                    <tr className='flex gap-[50px] mt-1 w-[100%]' key={item.id}>
                                        {/* <td className="p-2 text-md w-[2%] text-gray-700 whitespace-nowrap">{item.id}</td> */}
                                        <td className="p-2 text-wrap w-[13%] text-md text-gray-700 whitespace-nowrap">{item.heading}</td>
                                        <td className="p-2  text-wrap w-[61%] text-md text-gray-700 whitespace-nowrap">{item.desc}</td>
                                        {/* <td className="p-2 text-wrap w-[20%] text-md text-gray-700 whitespace-nowrap">{item.claim}</td> */}
                                        <td className="p-2 w-[7%] text-md text-gray-700 whitespace-nowrap">
                                            <button onClick={() => handleClick(item.id)}>Delete</button>
                                        </td>
                                    </tr>

                                ))
                            }
                        </tbody>
                    </table>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden w-[155%] max-w-maxContent">
                    {
                        data && data.map((data) => (
                            <div className="bg-white space-y-1 p-4 rounded-lg shadow" key={data.id}>
                                <div className="flex items-start space-x-2 text-sm justify-between">

                                    <div className="text-md text-gray-500 text-wrap"> Heading :{data.heading}</div>
                                </div>
                                <div className="text-sm text-gray-700 text-wrap">
                                    Description : {data.desc}
                                </div>
                                {/* <div className='text-sm text-gray-700 text-wrap'>
                                    Claim : {data.claim}
                                </div> */}
                                <div className="text-sm text-white text-center py-2 px-2 bg-red-700 w-[30%]">
                                    <button onClick={() => handleClick(data.id)}>Delete</button>
                                </div>

                            </div>
                        ))
                    }

                </div>
            </div>
            {
                active === true ? (
                    <div className='bg-white top-32 absolute right-[-25px] md:right-[350px] h-[30vh] flex flex-col gap-10 justify-center md:w-[30vw] w-[40vh] items-center'>
                        <div>
                            <div className='text-xl'>Are You Sure to delete?</div>
                        </div>
                        <div className='flex justify-between gap-20'>
                            <button className='px-4 py-2 bg-red-700 text-white' onClick={handleClick1}>Delete</button>
                            <button className='py-2 px-4 bg-blue-800 text-white' onClick={() => setActive(false)}>Close</button>
                        </div>
                    </div>
                ) : ""
            }
        </div>
    )
}

export default Discount