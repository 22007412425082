import toast from "react-hot-toast"
import { apiConnector } from "../apiConnector"
// import { setData, setDataId } from "../slice/dataSlice"
// import { baseUrl } from "./api"


export const getAnnoucement = async () => {

    // const URL = "http://localhost:4000/api/v1/getAnnoucement"
    const URL = "https://kishkindaresort.com/api/v1/getAnnoucement"
    let result = []
    try {
        const response = await apiConnector("GET", URL, null,)
        // console.log("response", response)
        result = response?.data?.data
        // console.log("result", result)
        // setData(result)
    } catch (error) {
        console.log("GET ALL DATA API ERROR............", error)
    }
    return result
}
export const getOffer = async () => {
    // const URL = "https://athnum.com/api/v1/getAll"
    const URL = "https://kishkindaresort.com/api/v1/getOffer"
    // const URL = "http://localhost:4000/api/v1/getOffer"
    let result = []
    try {
        const response = await apiConnector("GET", URL, null)
        // console.log("response", response)
        result = response?.data?.data
        // console.log("result", result)
        // setData(result)
    } catch (error) {
        console.log("GET ALL DATA API ERROR............", error)
    }
    return result
}
export const getImage = async (token) => {

    const URL = "https://kishkindaresort.com/api/v1/getImage"
    // const URL = "http://localhost:4000/api/v1/getImage"
    let result = []
    try {
        const response = await apiConnector("GET", URL, null, {
            Authorization: `${token}`,
        })
        // console.log("response", response)
        result = response?.data?.data
        // console.log("result", result)
        // setData(result)
    } catch (error) {
        console.log("GET ALL DATA API ERROR............", error)
    }
    return result
}

export const deleteDiscount = async (id, token) => {
    const URL = `https://kishkindaresort.com/api/v1/delete/${id}`
    // const URL = `http://localhost:4000/api/v1/delete/${id}`

    let result = null
    try {
        const response = await apiConnector("DELETE", URL, null, {
            Authorization: `${token}`,
        })
        // console.log("response", response)
        result = response?.data?.data
        // console.log("result", result)
        toast.success("Offer deleted")
        // setData(result)
    } catch (error) {
        console.log("GET ALL DATA API ERROR............", error)
    }
    return result
}
export const deleteImage = async (id, token) => {
    const URL = `https://kishkindaresort.com/api/v1/deleteImage/${id}`
    // const URL = `http://localhost:4000/api/v1/deleteImage/${id}`

    let result = null
    try {
        const response = await apiConnector("DELETE", URL, null, {
            Authorization: `${token}`,
        })
        // console.log("response", response)
        result = response?.data?.data
        toast.success("Image Deleted")
        // setData(result)
    } catch (error) {
        console.log("GET ALL DATA API ERROR............", error)
    }
    return result
}

export const deleteAnnoucement = async (id, token) => {
    const URL = `https://kishkindaresort.com/api/v1/deleteAnnoucement/${id}`
    // const URL = `http://localhost:4000/api/v1/deleteAnnoucement/${id}`

    let result = null
    try {
        const response = await apiConnector("DELETE", URL, null, {
            Authorization: `${token}`,
        })
        // console.log("response", response)
        result = response?.data?.data
        // console.log("result", result)
        toast.success("Annoucement deleted")
        // setData(result)
    } catch (error) {
        console.log("GET ALL DATA API ERROR............", error)
    }
    return result
}