// GoogleReview.js
import React from "react";
import "./GoogleReview.css"; // Import your CSS file for styling

const GoogleReview = () => {
  return (
    <div className="google-review">
      <a href="https://g.page/KishkindaResortHampi/review?nr" target="_blank">
        <img
          src="https://fatgalah.com.au/upload/modules/Google%20My%20Business%20Review.jpg"
          alt="Google Review"
          className="google-review-img"
        />
      </a>
    </div>
  );
};

export default GoogleReview;
