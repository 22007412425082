import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import "./Thankyou.css";
import Checked from "./check-mark.png";
const ThankYou = () => {
  return (
    <div className="thank-you-cont">
      <div className="thakyou-container">
        {/* <div className="thakyou-containerss"> */}
        <img src={Checked} alt="" height={300} width={200} />

        <h1 className="thankyou-head">Thank You For your Message</h1>
        <h2 className="thankyou-head">
          We will in <span style={{ color: "#6bb577" }}>touch soon</span>
        </h2>
        {/* </div> */}

        {/* <Row>
                <Col >
                
                </Col>
            </Row> */}
      </div>
      <div className="thankyou-secondcont">
        <img
          src="http://localhost:3000/Images/Kiskinda_Heritage_Resort_logo2.png"
          alt=""
        />
        <h2 className="thankyou-headss">
          © Copyright Company. All Rights Reserved.
        </h2>
      </div>
    </div>
  );
};

export default ThankYou;
