const Sdata = [
  {
    id: 1,
    title: "Non AC Cottage",
    image: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC5.jpg",
    desc: "Garden View Non AC Cottage - with Breakfast",
    sidepara: "Garden View Non Ac Cottage is well equipped with Double cots, sofa furniture, fan, long mirror, CTV, attached bathroom, hot and cold water, and shower. Incl - Welcome Juice, 2 Water Bottle, Complimentary Veg. Breakfast - Free use of park",
    price: "5000",
    detailsLink: "/room/non-ac-cottage-kiskinda-heritage-resort",

    images: [
      {
        src: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC1.jpg",
        original: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC1.jpg",
        width: 320,
        height: 174,
        tags: [
          { value: "Garden View", title: "Garden View" },
          // { value: " Non AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC2.jpg",
        original: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC2.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          { value: "Non AC Cottage", title: "Non AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC3.jpg",
        original: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC3.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC4.jpg",
        original: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC4.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC5.jpg",
        original: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC5.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC6.jpg",
        original: "/Images/NON_AC_COTTAGE/Kiskinda_Heritage_Resort_Non_AC_Cottage_NAC6.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },

    ]
  },
  {
    id: 2,
    title: "AC Cottage",
    image: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC6.jpg",
    desc: "Garden View AC Cottage - with Breakfast",
    sidepara: "Garden View Ac Cottage is well equipped with Twin cots, sofa furniture, AC, fan, long mirror, CTV, attached bathroom, hot and cold water, and shower. Incl - Welcome Juice, 2 Water Bottle, Complimentary Veg. Breakfast - Free use of park",
    price: "6000",
    detailsLink: "/room/ac-cottage-kiskinda-heritage-resort",

    images: [
      {
        src: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC1.jpg",
        original: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC1.jpg",
        width: 320,
        height: 174,
        tags: [
          { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC2.jpg",
        original: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC2.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC3.jpg",
        original: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC3.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC4.jpg",
        original: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC4.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC5.jpg",
        original: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC5.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC6.jpg",
        original: "/Images/AC_COTTAGE/Kiskinda_Heritage_Resort_AC_Cottage_AC6.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
    ]
  },
  {
    id: 3,
    title: "AC Suite Room",
    image: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS7.jpg",
    desc: "Pool View Suite Room - with Breakfast",
    sidepara: "Pool View Ac Suite Room is well designed with modern amenities, with King cot, sofa furniture, Split AC, fan, dining table, long mirror, satellite TV, attached bathroom, hot and cold water, and shower, living room with cot and sofa, balcony, room front parking, mountain/ pool /garden view. Incl - Welcome Juice, 2 Water Bottle, Complimentary Veg. Breakfast - Free use park",
    price: "7000",
    detailsLink: "/room/ac-suite-room-kiskinda-heritage-resort",

    images: [
      {
        src: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS1.jpg",
        original: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS1.jpg",
        width: 320,
        height: 174,
        tags: [
          { value: "Pool View", title: "Pool View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS2.jpg",
        original: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS2.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          { value: "AC Suite Room", title: "AC Suite Room" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS3.jpg",
        original: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS3.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS4.jpg",
        original: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS4.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS5.jpg",
        original: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS5.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS6.jpg",
        original: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS6.jpg",
        width: 320,
        height: 174,
        tags: [
          // { value: "Garden View", title: "Garden View" },
          // { value: "AC Cottage", title: "AC Cottage" },
        ],
        caption: "Kiskinda Heritage Resort",
      },
      {
        src: "/Images/AC_SUITE/DSC04709.JPG",
        original: "/frontend/public/Images/AC_SUITE/DSC04709.JPG",
        width: 320,
        height: 174,
        tags: [

        ],
        caption: "kiskinda Heritage Resort"

      },
      {
        src: "/Images/AC_SUITE/DSC04715.JPG",
        original: "/frontend/public/Images/AC_SUITE/DSC04715.JPG",
        width: 320,
        height: 174,
        tags: [

        ],
        caption: "kiskinda Heritage Resort"
      },
      {
        src: "/Images/AC_SUITE/DSC04685.JPG",
        original: "/frontend/public/Images/AC_SUITE/DSC04685.JPG",
        width: 320,
        height: 174,
        tags: [

        ],
        caption: "kiskinda Heritage Resort"
      },
      {
        src: "/Images/AC_SUITE/IMG-20220703-WA0009.jpg",
        original: "/frontend/public/Images/AC_SUITE/IMG-20220703-WA0009.jpg",
        width: 320,
        height: 174,
        tags: [

        ],
        caption: "kiskinda Heritage Resort"
      },
      {
        src: "/Images/AC_SUITE/FB_IMG_1603276964668.jpg",
        original: "/frontend/public/Images/AC_SUITE/FB_IMG_1603276964668.jpg",
        width: 320,
        height: 174,
        tags: [

        ],
        caption: "kiskinda Heritage Resort"
      },
      {
        src: "/Images/AC_SUITE/DSC_1923.JPG",
        original: "/frontend/public/Images/AC_SUITE/DSC_1923.JPG",
        width: 320,
        height: 174,
        tags: [

        ],
        caption: "kiskinda Heritage Resort"
      },
      {
        src: "/Images/AC_SUITE/DSC01887.JPG",
        original: "/frontend/public/Images/AC_SUITE/DSC01887.JPG",
        width: 320,
        height: 174,
        tags: [

        ],
        caption: "kiskinda Heritage Resort"
      }

    ]
  },
  {
    id: 4,
    title: "AC Executive Room",
    image: "/Images/AC_SUITE/Kiskinda_Heritage_Resort_AC_Suite_AS4.jpg",
    desc: "Pool View Executive Room - with Breakfast",
    soon: "Coming Soon...",
    sidepara: "COMING SOON...",
    price: "",
    // detailsLink: "/room/ac-executive-room-kiskinda-heritage-resort",

    images: []
  }
]

export default Sdata
