import React from 'react';
import InnerBanner from './InnerBanner';
import Welcome from './Welcome';
import ResortAmenities from './ResortAmenities';
import WaterPark from './WaterPark';
import Others from './Others';
import './About.css'

const About = () => {
  return (
    <div className='about'>
    <InnerBanner heading="About The Resort"/>
    <Welcome/>
    <ResortAmenities/>
    <WaterPark/>
    <Others/>
    </div>
  );
};

export default About;
