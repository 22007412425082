import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Sdata from "./Sdata"; // Import your data file
import { Link } from "react-router-dom";
import './OtherRooms.css'

const OtherRooms = ({ currentRoom }) => {
  // Filter out the current room from the data
  const otherRoomsData = Sdata.filter((room) => room.id !== currentRoom.id);

  return (
    <Container className="my-5 otherrooms">
      <h2 className="text-center mb-4">Other Rooms</h2>
      <Row className="g-4">
        {otherRoomsData.map((room) => (
          <Col key={room.id} md={4}>
            <Card className="other-room-card">
              <Card.Img className="other-room-image" variant="top" src={room.image} />
              <Card.Body>
                <Card.Title>{room.title}</Card.Title>
                <Card.Text>{room.desc}</Card.Text>
                {
                  room.soon ? <Card.Text className="italic mt-2 text-[17px] text-[red]">{room.soon}</Card.Text> : ""
                }
                {
                  room.detailsLink ? <Link
                    to={room.detailsLink}
                    className=""
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "rgba(211, 204, 74, 0.85)",
                      color: "white",
                      fontWeight: 400,
                      textDecoration: "none",
                      transition: "0.5s",
                      display: "inline-block",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor =
                        "rgba(236, 230, 106, 0.85)";
                      e.currentTarget.style.color = "white";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor =
                        "rgba(211, 204, 74, 0.85)";
                      e.currentTarget.style.color = "white";
                    }}>
                    More Details
                  </Link> : ""
                }
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default OtherRooms;
