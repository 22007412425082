import React from "react";
import "./Welcome.css";

const Welcome = () => {
  return (
    <div className="about_us_content">
    <div className="welcome">
      <div className="container">
        <h2 className="text-center">
          Welcome to Kishkinda Heritage Resort
        </h2>
        <p className="text-center">
          KISHKINDA HERITAGE RESORT a Holiday Resort and Water park near
          Anjanadri Hill, ANEGUNDI and close to HAMPI, Karnataka and it is just
          20 kms away UNESCO World Heritage sites " HAMPI " Resort established
          in 1999 and later slowly expanded as Holiday Resort with Water Park
          with all modern amenities and services.<br/>The Resort spread over 6 acres
          surrounded beautiful hills, the spreaded 2 Kms Lake behind, the water
          flows down on rocks, the cascades, pools, canals, splendour natural
          beauty, on the other side at front the spreaded green paddy fields,
          the rich as it is in splendid scenery and water flowing attracts
          tourist to unforgettable.<br/> Kishkinda Heritage Resort offers with the
          luxurious Suites, Village Ethnic Cottages, along with Water Park
          allows an escape from the hustle and bustle of everybody life,
          Children Park, water sliding, playground, the multi cuisine Restaurant
          offers a delicious Indian food, the well-arranged conference hall for
          business meeting, Banquet Hall, the green park for wedding, birthday
          function and family gatherings.
        </p>
      </div>
    </div>
    </div>
  );
};

export default Welcome;
