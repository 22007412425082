// JoinPage.js
import React from "react";
// import { Gallery } from "react-grid-gallery";
// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";
// import { images } from "./GalleryImages";
// import './Gallery.css'
// import images from "./GalleryImages";
import InnerBanner1 from "../About/InnerBanner2";
import Galleryy from "./Gallery";

const GalleryPage = () => {
    return (
        <div>
            <InnerBanner1 heading="Gallery" />
            <Galleryy/>
        </div>
    );
};

export default GalleryPage;
