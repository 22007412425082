import React from 'react';
import { Container, Card, Col } from 'react-bootstrap';
import './PrivacyPolicy.css'; // Import your CSS file for styling

const PrivacyPolicy = () => {
  return (
    <Container className="privacy-policy-container">
      <Col md={{ span: 8, offset: 2 }}>
        <Card className="privacy-policy-card">
          <Card.Body>
            <Card.Title className="privacy-policy-heading">Privacy Policy</Card.Title>
            <Card.Text className="privacy-policy-intro">
              The Privacy policy and security of your information are very important to us when you book a room or service at our property through our website/Mobile app, online Travel agencies, offline booking, and direct booking. We want to trust that the information you have provided to us is being properly managed and protected.
            </Card.Text>
            <Card.Text className="privacy-policy-section">
              <h5>Information we collect:</h5>
              <p>
                Information you give us, you have to provide us the various information that personally identifies you, the detail would be as Your Name, email address, home or business address, contact number, nationality, payment option information and GST number if you are a company.
              </p>
              {/* ... (Rest of the content for this section) ... */}
            </Card.Text>
            <Card.Text className="privacy-policy-section">
              <h5>Secure your Information</h5>
              <p>
                We are committed to protecting the confidentiality and security of the information that you provide to us. To do this technical, physical security measures are put in place to protect against any unauthorized access, disclosure, damage, or loss of your information.
              </p>
              {/* ... (Rest of the content for this section) ... */}
            </Card.Text>
            <Card.Text className="privacy-policy-section">
              <h5>Children</h5>
              <p>
                Our websites are not intended for children, and we do not intentionally solicit or collect personal information from individuals under the age of 18. If we are notified or otherwise discover that a minor’s personal information has been improperly collected, we will take all commercially reasonable steps to delete that information.
              </p>
              {/* ... (Rest of the content for this section) ... */}
            </Card.Text>
            <Card.Text className="privacy-policy-section">
              <h5>Changes of the privacy policy</h5>
              <p>
                In some instances, we may have to change, modify or amend this privacy policy to comply with the regulatory environment or the needs of our business. We reserve the right, at any time and without notice, to add to, change, update or modify this privacy policy, subject to any applicable legal requirements.
              </p>
              {/* ... (Rest of the content for this section) ... */}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Container>
  );
};

export default PrivacyPolicy;
