import React from "react";
// import Table from "../Table/Table";
import "./MainDash.css";
// import Member from "../Member/Member";
// import NatureBusiness from "../NatureBusiness/NatureBusiness";
// import RegisterationType from "../RegisterationType/RegisterationType";
import Discount from "../Discount/Discount";
import Annoucemnt from "../Annoucement/Annoucement";
import Gallery from "../Galleries/Gallery";
import { UilSignOutAlt } from '@iconscout/react-unicons';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/operation/authApi";

const MainDash = ({ active }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleClick = () => {
        dispatch(logout(navigate))
    }

    return (
        <div className="MainDash min-h-screen">
            <div className="flex w-[100%] justify-between items-center mt-2">
                <h1 className="text-2xl mt-2">Dashboard</h1>
                <div className="flex mr-0 md:mr-10 cursor-pointer" onClick={handleClick} >
                    <UilSignOutAlt /> Logout
                </div>
            </div>
            {
                active === 2 && <Discount />
            }
            {
                active === 1 && <Annoucemnt />
            }
            {
                active === 3 && <Gallery />
            }
        </div>
    );
};

export default MainDash;
