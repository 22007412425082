import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import TripAdvisorReview from "./TripAdvisorReview";
import GoogleReview from "./GoogleReview";
import FacebookWindow from "./FacebookWindow";

const AddReviews = () => {
  return (
    <div className="add-reviews bg_light_blue2">
      {/* <h2 className="custom_title text-uppercase text-center">
          Add Your Reviews About Our Hotel
        </h2> */}
      <Container className="my-4">
        <Row>
          <Col md={4}>
            <Card className="review-card">
              <TripAdvisorReview />
            </Card>
          </Col>
          <Col md={4}>
            <Card className="review-card">
              <GoogleReview />
            </Card>
          </Col>
          <Col md={4}>
            <Card className="review-card">
              <FacebookWindow />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddReviews;
