// Hero.js

import React from "react";
// import Breadcrumbs from "./Breadcrumbs";
import "./Hero.css"; // Import your CSS file

const Hero = ({ images }) => {
  return (
    <div className="hero-container">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="2000"
        data-bs-pause="false"
      >
        <div className="carousel-inner col-xxl-4">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <img
                src={image}
                className="d-block w-100"
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="overlays"></div>
      <div className="text-containers">
        <h1 className="headings">Luxury Awaits</h1>
        <p className="subheadings">
          "Discover unparalleled luxury and personalized service at our hotel,
          where every stay is an exquisite experience."
        </p>
        {/* <Breadcrumbs /> */}
      </div>
    </div>
  );
};

export default Hero;
