import React, {  useState } from 'react'
import './Admin.css'
import Sidebar from '../Sidebar/Sidebar'
import MainDash from '../Main/MainDash'
// import { useSelector } from 'react-redux'
// import { getAnnoucement } from '../../../redux/operation/dataApi'

const Admin = () => {
    const [active, setActive] = useState(1)
    // const { token } = useSelector(state => state.auth)
    // const [item, setItem] = useState([])
    // useEffect(() => {
    //     const fetchDetail = async () => {
    //         const result = await getAnnoucement(token)
    //         if (result) {
    //             setItem(result)
    //         }
    //     }
    //     fetchDetail()
    // }, [])
    // console.log(item)
    // console.log("item", item)
    return (
        <div className="App">
            <div className="AppGlass">
                <Sidebar active={active} setActive={setActive} />
                <MainDash active={active} />
            </div>
        </div>
    )
}

export default Admin