import React, { useState, useEffect } from "react";
import PopularData from "./PopData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="control-btn" onClick={onClick}>
      <button className="next">
        <i className="fa fa-long-arrow-alt-right"></i>
      </button>
    </div>
  );
};
const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="control-btn" onClick={onClick}>
      <button className="prev">
        <i className="fa fa-long-arrow-alt-left"></i>
      </button>
    </div>
  );
};

const Cards = () => {
  const [slidesToShow, setSlidesToShow] = useState(4);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  };
  
  

  const calculateSlidesToShow = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 4; // Show 4 cards for large screens
    } else if (screenWidth >= 900) {
      return 3; // Show 3 cards for medium screens
    } else {
      return 1; // Show 1 card for small screens
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const newSlidesToShow = calculateSlidesToShow();
      setSlidesToShow(newSlidesToShow);
    };

    handleResize(); // Initial calculation

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Slider {...settings}>
        {PopularData.map((value) => {
          return (
            <div key={value.id}>
              <div className="cards">
                <div className="item">
                  <div className="imgae">
                    <img src={value.image} alt="" />
                  </div>
                  <div className="details">
                    <h2>{value.name}</h2>
                    <h3>{value.details}</h3>
                    <h4>{value.timing}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default Cards;
