import React, { useState } from "react";
import { Navbar, Nav, Container, Image, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./Navbar.css";
import NavbarBelow from "./NavbarBelow";

const NavbarComponent = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Navbar
        expanded={expanded}
        collapseOnSelect
        expand="md"
        // bg="light"
        // variant="light"
        className="custom-navbar z-2">
        <Container fluid>
          <Navbar.Brand as={Link} to="/" className="logo">
            <Image
              src="Images/Kiskinda_Heritage_Resort_logo2.png"
              alt="Logo"
            fluid
            />
          </Navbar.Brand>

          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : "expanded")}
            aria-controls="responsive-navbar-nav"
          />

          <Navbar.Collapse in={expanded}>
            <Nav className={` ${expanded ? "full-width" : ""}`}>
              <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
                HOME
              </Nav.Link>
              <Nav.Link as={Link} to="/about" onClick={() => setExpanded(false)}>
                ABOUT THE RESORT
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/gallery"
                onClick={() => setExpanded(false)}>
                GALLERY
              </Nav.Link>
              <Nav.Link as={Link} to="/rooms" onClick={() => setExpanded(false)}>
                ROOMS
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/facilities"
                onClick={() => setExpanded(false)}>
                FACILITIES
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => setExpanded(false)}>
                CONTACT US
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          {/* <Dropdown>
          <Dropdown.Toggle
            variant={` ${expanded ? "active" : ""}`}
            className={`join-btn ${expanded ? "expanded" : ""}`}>
            {expanded ? (
              <FontAwesomeIcon icon={faUser} />
            ) : (
              <>
                <FontAwesomeIcon icon={faUser} className="mr-2" /> SIGN IN OR
                JOIN
              </>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/signin">
              Sign In
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/join">
              Join
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

          <Nav.Link
            className="navright"
            as={Link}
            to="https://kishkindawaterpark.com/"
            onClick={() => setExpanded(false)}>
            WATER PARK
          </Nav.Link>
        </Container>
      </Navbar>
      <NavbarBelow />
    </>
  );
};

export default NavbarComponent;
