import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import PropTypes from 'prop-types'
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './InnerBanner1.css'; // Import your custom CSS for styling

const InnerBanner2 = ({ heading }) => {
    return (
        <div className='bgi'>
            <div className="page-heading">
                <Container fluid>
                    <Row>
                        <Col>
                            <h1>{heading}</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default InnerBanner2;
