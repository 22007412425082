import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ContactUs.css"; // Add a separate CSS file for styling
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you can perform any actions like sending the form data to a server
    console.log(formData);
    setLoading(true);
    const data = await axios.post(
      `https://kishkindaresort.com/api/v1/sendEmail`,
      formData
    );
    console.log(data);
    if (data.data.success === true) {
      console.log("SEND SUCCESSFULLYYYYYYYYYYYYYYYYYYYY");
      setLoading(false);
      navigate("/thankyou");
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    }

    // Reset the form fields after submission
  };

  return (
    <div className="contact_us_content">
      <Container className="contact-us mt-5">
        <Row>
          {/* Left side with form and map */}
          <Col md={8}>
            {/* Form */}
            <Card className="contact-card">
              <Card.Body>
                <Card.Title className="mb-4">Get in touch with us</Card.Title>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="name">
                    <Form.Control
                      type="text"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Control
                      type="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phone">
                    <Form.Control
                      type="text"
                      placeholder="Your Phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="subject">
                    <Form.Control
                      type="text"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="message">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Your Message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="mt-3">
                    {loading ? <div>Waiting....</div> : "Submit"}
                  </Button>
                </Form>
              </Card.Body>
            </Card>

            {/* Google Map */}
            <div className="google-map">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15389.552965626792!2d76.443134!3d15.355446!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb77fdadf83b537%3A0x313c8c8110022f0!2sKishkinda%20Heritage%20Resort%20-%20Near%20Anjanadri%20Hill%2C%20Hampi%2C%20Karnataka!5e0!3m2!1sen!2sus!4v1707210585848!5m2!1sen!2sus"
                width="100%"
                height="300"
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </Col>

          {/* Right side with three cards */}
          <Col md={4}>
            {/* Card for Address Details */}
            <Card className="info-card mb-4">
              <Card.Body>
                <Card.Title className="mb-3">Address Details</Card.Title>
                <Card.Text>
                  Near Stone Bridge cross,
                  <br /> ANEGUNDI - 583234 ,<br /> Across the River- Hampi,
                  <br /> Karnataka, India
                </Card.Text>
              </Card.Body>
            </Card>

            {/* Card for Contact Information 1 */}
            <Card className="info-card mb-4">
              <Card.Body>
                <Card.Title className="mb-3">Reception</Card.Title>
                <Card.Text>
                  <strong>Phone:</strong> +918533200700, +918310256712
                </Card.Text>
                <Card.Text>
                  <strong>Whatsapp:</strong> +919482810168
                </Card.Text>
                <Card.Text>
                  <strong>Email:</strong> kishkindaresort@gmail.com
                </Card.Text>
              </Card.Body>
            </Card>

            {/* Card for Contact Information 2 */}
            <Card className="info-card">
              <Card.Body>
                <Card.Title className="mb-3">Reservation</Card.Title>
                <Card.Text>
                  <strong> Off. Phone:</strong> +918533200708
                </Card.Text>
                <Card.Text>
                  <strong>General Manager:</strong> +918217687224, +919449544168
                </Card.Text>
                <Card.Text>
                  <strong>Email:</strong> sales.kishkindaresort@gmail.com
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
