import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <footer className="footer">
        <Container>
          <Row>
            {/* Social Media Section */}
            <Col md={6} lg={4}>
              <div>
                <h2>Contact Us</h2>
                <p>
                  <i className="far fa-envelope"></i>{" "}
                  sales.kishkindaresort@gmail.com
                </p>
                <p>
                  <i className="fas fa-phone"></i>
                  {" "}
                  +918533200700
                </p>
                <p>Near Stone Bridge cross, ANEGUNDI, Hampi</p>
              </div>
              <div>
                <h2>Social Media</h2>
                <div className="icons flex_space">
                  <Link to="https://www.facebook.com/KishkindaResort.Hampi">
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                  <Link to="mailto:sales.kishkindaresort@gmail.com?Subject=Enquiry">
                    <i className="far fa-envelope"></i>
                  </Link>
                  <Link to="https://twitter.com/KishkindaResort">
                    <i className="fab fa-twitter"></i>
                  </Link>
                  {/* <Link to="#">
                    <i className="fab fa-linkedin"></i>
                  </Link> */}
                  <Link to="https://www.instagram.com/kishkindaresort.hampi/">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  {/* <Link to="#">
                    <i className="fab fa-pinterest"></i>
                  </Link> */}
                  {/* <Link to="#">
                    <i className="fab fa-youtube"></i>
                  </Link> */}
                </div>
              </div>
            </Col>

            <Col xs={6} sm={4} md={6} lg={3}>
              <h2 >Navigation</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>

                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                {/* <li>
                  <Link to="/">Water Park</Link>
                </li>
                <li>
                  <Link to="/events">Wedding & Events</Link>
                </li>
                <li>
                  <Link to="/safety">Safety Measures</Link>
                </li> */}
                {/* <li>
                  <Link to="/overview">Overview</Link>
                </li> */}
                <li>
                  <Link to="/rooms">Rooms</Link>
                </li>
                <li>
                  <Link to="/facilities">Facilities</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
              </ul>
            </Col>

            {/* Policies Section */}
            <Col xs={6} sm={4} md={6} lg={2}>
              <h2>Policies</h2>
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/cancellation-policy">Cancellation Policy</Link>
                </li>
                <li>
                  <Link to="/general-hotel-policy">General hotel Policy</Link>
                </li>
                {/* <li>
                  <Link to="/terms-conditions">Terms & Conditions</Link>
                </li> */}
              </ul>
            </Col>

            {/* Terms and Conditions Section */}
            <Col sm={4} md={6} lg={3}>
              <div>
                <h2>Newsletter</h2>
                <p>Follow us to receive the latest news</p>
                <form action="">
                  <input type="email" name="" id="" placeholder="Email ID" />
                  <button type="submit" className="btn">
                    Subscribe
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="legal">
        <span className="cp_rt">
          Copyright © 2024 www.kishkindaheritage.com All Rights Reserved. |
          Designed &amp; Developed with&nbsp;
          <i className="fas fa-heartbeat"></i>&nbsp;by&nbsp;
          <a href="https://www.eweblink.net/" target="blank">
            eWeblink
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
