// FacebookWindow.js
import React from "react";
import "./FacebookWindow.css"; // Import your CSS file for styling

const FacebookWindow = () => {
  return (
    <div className="facebook-window">
      {/* Here you can place your Facebook widget or iframe */}
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FKishkindaResort.Hampi%3Fref%3Demded_page&tabs=timeline&width=340&height=0&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
        width="340"
        height="180"
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen={true}
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </div>
  );
};

export default FacebookWindow;
