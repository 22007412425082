// ResponsiveComponent.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './ResponsiveComponent.css'; // Import your custom styles

const ResponsiveComponent = () => {
  return (
    <div className='about_us_content'>
      <Container fluid>
        <Row>
          <Col md={6} lg={3}>
            <Card className="sub-container custom-card pink">
              <Card.Body>
                <h2>Conference Hall</h2>
                <p>
                  The Resort having a 175- and 75-member sitting capacity with projector and all conference facilities.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card className="sub-container custom-card blue">
              <Card.Body>
                <h2>Restaurant</h2>
                <p>
                  Vegetarian Restaurant with Multi cuisine, hygienic preparation, homemade food, good service, Non-vegetarian restaurant and Bar.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card className="sub-container custom-card green">
              <Card.Body>
                <h2>Travel Desk</h2>
                <p>
                  Car rental service for Transfers and Sightseeing.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card className="sub-container custom-card purple">
              <Card.Body>
                <h2>Other Facilities</h2>
                <p>
                  WI – FI, uninterrupted power supply, heavy vehicle parking ACTIVITIES: Swimming, Cricket, volleyball, badminton, trekking
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResponsiveComponent;
