import React from "react";
import { Container, Card } from "react-bootstrap";
import "./GeneralHotelPolicy.css"; // Import your CSS file

const GeneralHotelPolicy = () => {
  return (
    <Container>
      <Card className="policy-card">
        <Card.Body>
          <h2 className="policy-heading">General Hotel Policy</h2>
          <ul className="policy-list">
            <li className="policy-point">
              <span className="tick-icon">&#10004;</span>
              It is mandatory for guests to present valid photo identification at the time of check-in
            </li>
            <li className="policy-point">
              <span className="tick-icon">&#10004;</span>
              Early check-in or late check-out is subject to availability and may be chargeable by the hotel.
            </li>
            <li className="policy-point">
              <span className="tick-icon">&#10004;</span>
              The standard check-in time is 10.00 AM and check-out is 09.00 AM. After booking you can call the hotel for early check in if required.
            </li>
            <li className="policy-point">
              <span className="tick-icon">&#10004;</span>
              Extra bed charges, snacks, food and beverages will be charged other than booked
            </li>
            <li className="policy-point">
              <span className="tick-icon">&#10004;</span>
              The hotel reserves the right of admission
            </li>
            <li className="policy-point">
              <span className="tick-icon">&#10004;</span>
              Accommodation can be denied to guests posing as a couple if suitable proof of identification is not presented at check-in time. Reception Staff will not be responsible for any check-in denied by the hotel due to the aforesaid reason.
            </li>
            <li className="policy-point">
              <span className="tick-icon">&#10004;</span>
              The Management will not be responsible for the loss or damage of any of your personal belongings.
            </li>
            <li className="policy-point">
              <span className="tick-icon">&#10004;</span>
              All disputes shall be subject to jurisdiction of courts at Gangavathi.
            </li>
          </ul>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default GeneralHotelPolicy;
