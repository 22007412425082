import React from "react";
import Hero from "./Hero";
import Announcement_N_Offers from "./Announcement_N_Offers";
import RoomsType from "./RoomsType";
import AttractionPoints from "./AttractionPoints";
import Testimonials from "./Testimonials";
import testimonialsData from "./testimonialsData.json";
// import TripAdvisorReview from "./TripAdvisorReview";
// import GoogleReview from "./GoogleReview";
// import FacebookWindow from "./FacebookWindow";
import AddReviews from "./AddReviews";
// import { Card, Container, Row, Col } from "react-bootstrap"; // Import Bootstrap components

export const Home = () => {
  const images = [
    "Images/HERO/Kiskinda_Heritage_Resort_s1.jpg",
    "Images/HERO/Kiskinda_Heritage_Resort_s2.jpg",
    "Images/HERO/Kiskinda_Heritage_Resort_s3.jpg",
    "Images/HERO/Kiskinda_Heritage_Resort_s4.jpg",
    "Images/HERO/Kiskinda_Heritage_Resort_s5.jpg",
    "Images/HERO/Kiskinda_Heritage_Resort_s6.jpg",
    "Images/HERO/Kiskinda_Heritage_Resort_s7.jpg",
    "Images/HERO/Kiskinda_Heritage_Resort_s8.jpg",
  ];

  return (
    <>
      <Hero images={images} />
      <Announcement_N_Offers />
      <RoomsType />
      <AttractionPoints />
      <Testimonials testimonials={testimonialsData} />
      {/* <Container className="my-5">
        <Row>
          <Col md={6}>
            <Card className="facebook-window">
              <FacebookWindow />
            </Card>
          </Col>
          <Col md={6}>
              <Col md={6}>
                <Card className="review-card">
                  <TripAdvisorReview />
                </Card>
              </Col>
              <Col md={6}>
                <Card className="review-card">
                  <GoogleReview />
                </Card>
              </Col>
          </Col>
        </Row>
      </Container> */}
      <AddReviews/>
    </>
  );
};
