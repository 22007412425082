import React from 'react';
import InnerBanner from '../About/InnerBanner';
import ContactUs from './ContactUs';
import './Contact.css'


const Contact = () => {
  return (
    <div className='contact'>
    <InnerBanner heading="Contact Us"/>
    <ContactUs/>
    </div>
  );
};

export default Contact;
