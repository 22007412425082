import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";

const Modal = ({
    clickedImg,
    setClickedImg,
    handelRotationRight,
    handelRotationLeft
}) => {
    const handleClick = (e) => {
        if (e.target.classList.contains("dismiss")) {
            setClickedImg(null);
        }
    };

    return (
        <>
            <div className="overlay dismiss" onClick={handleClick}>
                <img src={clickedImg} alt="bigger pic" />
                <span className="dismiss" onClick={handleClick}>
                    X
                </span>
                <div onClick={handelRotationLeft} className="left">
                    <div>
                        <FaChevronLeft size={45} color="white" className="cursor-pointer" />

                    </div>
                </div>
                <div onClick={handelRotationRight} className="overlay-arrows_right">
                    <div>
                        <FaChevronRight size={45} color="white" className="cursor-pointer" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
