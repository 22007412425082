import React from 'react';
import { Container, Card, Col } from 'react-bootstrap';
import './CancellationPolicy.css';

const CancellationPolicy = () => {
  return (
    <Container className="cancellation-policy-container">
      <Col md={{ span: 8, offset: 2 }}>
        <Card className="cancellation-policy-card">
          <Card.Body>
            <Card.Title className="cancellation-policy-heading">Hotel Cancellation Policy</Card.Title>
            <Card.Text className="cancellation-policy-point">
              <span className="tick-icon">&#10003;</span> No refund on Cancellation before 72 hours check-in time
            </Card.Text>
            <Card.Text className="cancellation-policy-point">
              <span className="tick-icon">&#10003;</span> If any refunds against cancellation will be credited back to your account in 7-10 days
            </Card.Text>
            <Card.Text className="cancellation-policy-point">
              <span className="tick-icon">&#10003;</span> Balance Amount Should pay on Arrival
            </Card.Text>
            <Card.Text className="cancellation-policy-point">
              <span className="tick-icon">&#10003;</span> If a booking is cancelled or modified by the customer, the hotel will be notified of the changes, and the original confirmation email and booking ID that was generated will become null and void
            </Card.Text>
            <Card.Text className="cancellation-policy-point">
              <span className="tick-icon">&#10003;</span> No cancellation policy applies after check-in and check-out
            </Card.Text>
            <Card.Text className="cancellation-policy-point">
              <span className="tick-icon">&#10003;</span> No cancellation once guest checks in
            </Card.Text>
            <Card.Text className="cancellation-policy-point">
              <span className="tick-icon">&#10003;</span> No show, no refund
            </Card.Text>
            <Card.Text className="cancellation-policy-point">
              <span className="tick-icon">&#10003;</span> Child below 5 Years is Complimentary without an Extra bed
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Container>
  );
};

export default CancellationPolicy;
