// TripAdvisorReview.js
import React, { useEffect } from "react";
import "./TripAdvisorReview.css"; // Import your CSS file for styling

const TripAdvisorReview = () => {
  useEffect(() => {
    // TripAdvisor script execution
    const script = document.createElement("script");
    script.src =
      "https://www.jscache.com/wejs?wtype=cdswritereviewnew&amp;uniq=183&amp;locationId=676919&amp;lang=en_US&amp;border=true&amp;display_version=2";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="tripadvisor-review">
      <div id="TA_cdswritereviewnew183" className="TA_cdswritereviewnew">
        <div id="CDSWIDWRM" className="widWRMWrapper cx_brand_refresh1">
          <div className="widWRMInnerWrapperWithBorder">
            <div className="widWRMTAImg">
              <a target="_blank" href="https://www.tripadvisor.com/">
                <img
                  src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg"
                  alt="TripAdvisor"
                  className="tripadvisor-logo"
                />
              </a>
            </div>
            <div className="widWRMHName">Review Kishkinda Heritage Resort</div>
            <div className="widWRMReview">
              <input
                type="button"
                onClick={() =>
                  window.open(
                    "https://www.tripadvisor.com/UserReview-g319725-d676919-Kishkinda_Heritage_Resort-Hampi_Bellary_District_Karnataka.html?m=29834"
                  )
                }
                className="widWRMReviewButton"
                value="Write Review"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripAdvisorReview;
