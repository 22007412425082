import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const WaterPark = () => {
  return (
    <div className="about_us_content">
      <Container fluid>
        <Row>
          <Col md={6} className="order-md-1">
            <img
              src="Images/HERO/Kiskinda_Heritage_Resort_s1.jpg" // Replace with your image URL
              alt="Water Park"
              className="img-fluid"
            />
          </Col>
          <Col md={6} className="order-md-2">
            <h2>Water Park</h2>
            <p>
            The Tourist / traveller will prefer to have a comfortable holiday with their children when visit to new places. Kishkinda Water Park is a suitable and perfect place for spending holiday trip with family and children. And the best place for school and college students for having picnic or day out. Water Park having Sliding, Swimming pools, cascade, Children Park, playground. There are some day out package for corporate, groups, school and college students.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WaterPark;
