import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { deleteImage } from '../../../redux/operation/dataApi';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { RxCross1 } from "react-icons/rx";
import { IoAddCircleSharp } from 'react-icons/io5';
// import { getAllNatureOfBusiness, getAnnoucement, getDiscount, getImage, getOffer } from '../../../redux/operation/dataApi';

const Gallery = () => {

    const { token } = useSelector((state) => state.auth)
    const [active, setActive] = useState(false)
    const [delet, setDelet] = useState('')
    // const URL = "http://localhost:4000/api/v1"
    // const URL2 = "http://localhost:4000/"
    const URL = "https://kishkindaresort.com/api/v1"
    // const URL2 ="https://kishkindaresort.com/"
    // const [image, setImage] = useState();
    const [file, setFile] = useState()
    const [images, setImages] = useState([]);
    const [tag1, setTag1] = useState()
    const [tag2, setTag2] = useState()
    const [loading, setLoading] = useState(false)
    const [click, setClick] = useState(false)

    const handleFile = (e) => {
        setFile(e.target.files[0])
    }
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${URL}/getImage`);
                setImages(response.data);
                setLoading(true)
            } catch (error) {
                console.error('Failed to fetch images: ', error);
            }
        };
        fetchImages();
    }, [loading]);

    const handleUpload = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('image', file)
        formData.append('tag1', tag1)
        formData.append('tag2', tag2)
        const res = axios.post(`${URL}/image`, formData)
        if (res) {
            toast.success("Images Uploaded")
            setLoading(!loading)
            setFile('')
            setTag1('')
            setTag2('')
        } else {
            console.log("NOt success")
        }
    }

    // console.log("IMAGES IIN AGLLLERYYREA", images)
    // const handleDelete = async (id) => {
    //     const dele = await deleteImage(id, token)
    // }
    const handleDelete = async (id) => {
        setDelet(id)
        setActive(!active)
    }
    const handleClick1 = async () => {
        await deleteImage(delet, token)
        setActive(false)
        setLoading(!loading)
    }

    return (
        <div className='w-full ml-5'>
            <button onClick={() => setClick(!click)} className='ml-4 xl:ml-0 bg-[#F9CCD3] px-3 py-3 flex'>
                <IoAddCircleSharp size={25} className='mr-2' />Add Images
            </button>
            {
                click === true && <div className='flex flex-col gap-2'>
                    {/* <h2 className='text-xl'>Gallery</h2> */}
                    {/* <form onSubmit={handleSubmit}> */}
                    <form action="" onSubmit={handleUpload} className='flex flex-col gap-2'>
                        <label htmlFor="" className='mt-4'><span className='font-semibold'>Upload Image</span>
                            <input type="file" onChange={handleFile} className='xl:ml-2 ml-0 border px-2 py-2 w-[100%] xl:w-[50%] ' />
                        </label>
                        <label htmlFor=""><span className='font-semibold'>Enter Tag1</span>
                            <input type="text" value={tag1} name='tag1' onChange={(e) => setTag1(e.target.value)} className='xl:ml-8 ml-0 border px-2 py-2 w-[100%] xl:w-[50%]'
                                placeholder='Enter tag1' />
                        </label>
                        <label htmlFor=""><span className='font-semibold'>Enter Tag2</span>
                            <input type="text" value={tag2} name='tag2' onChange={(e) => setTag2(e.target.value)} className='xl:ml-8 ml-0 border px-2 py-2 w-[100%] xl:w-[50%]'
                                placeholder='Enter tag2' />
                        </label>
                        <button type='submit' className='border bg-red-700 px-3 py-2 text-white xl:w-[15%] w-[30%]'>Upload</button>
                    </form>
                    {/* </form> */}
                </div>
            }
            <div className='mt-4 relative'>
                <h2 className='text-xl mb-2 ml-10'>Images</h2>
                {/* <ul className=''>
                    {images.map((image) => (
                        <li key={image.id}>
                            <img src={`${image}`} alt={image.name} width={500} height={200} />
                        </li>
                    ))}
                </ul> */}
                <ul className='grid grid-cols-2 md:grid-cols-4 gap-x-1 gap-y-3 px-4'>
                    {images.map((image) => (
                        <li key={image.id}>
                            {/* <img src={image.image_url} alt={image.name} width={200} /> */}
                            <img src={`${URL}/images/` + image.image} alt={image.name} height={200} className='md:w-[200px] md:h-[150px]' />
                            <div className='flex gap-[2px] w-full flex-wrap'>
                                <p className='text-[12px] w-full'><span className='font-semibold'>Tag1 :</span> {image.tag1}</p>
                                <p className='text-[12px] w-full'><span className='font-semibold'>Tag2 :</span> {image.tag2}</p>
                            </div>
                            <button onClick={() => handleDelete(image.id)} className='px-3 mt-1 py-2 bg-red-800 text-white'>Delete</button>
                        </li>
                    ))}
                </ul>
                {/* <img src={`public/images/${images}`} alt="" /> */}
            </div>
            {
                active === true ? (
                    <div className='bg-white top-32 absolute right-[80px] md:right-[350px] h-[30vh] flex flex-col gap-10 justify-center md:w-[30vw] w-[40vh] items-center'>
                        <div>
                            <div className='text-xl'>Are You Sure to delete?</div>
                        </div>
                        <div className='flex justify-between gap-24'>
                            <button className='px-4 py-2 bg-red-700 text-white' onClick={handleClick1}>Delete</button>
                            <button className='py-2 px-4 bg-blue-800 text-white' onClick={() => setActive(false)}>Close</button>
                        </div>
                    </div>
                ) : ""
            }
        </div>
    )
}

export default Gallery