import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { UilSignOutAlt } from "@iconscout/react-unicons";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/operation/authApi";

const Sidebar = ({ active, setActive }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expanded, setExpaned] = useState(true);
  const [act, setAct] = useState(true);
  const [click, setClick] = useState(false);
  const [act2, setAct2] = useState(false);

  const sidebarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-60%",
    },
  };

  const handleClick = () => {
    dispatch(logout(navigate));
  };
  const handleClickDash = () => {
    setActive(1);
  };
  const handleClickDash2 = () => {
    setActive(2);
    setAct2(false);
    setAct(false);
  };
  const handleClickDash3 = () => {
    // setAct2(!act2)
    setAct(true);
    setActive(3);
    setClick(!click);
  };

  return (
    <>
      <div
        className="bars"
        style={expanded ? { left: "60%" } : { left: "5%" }}
        onClick={() => setExpaned(!expanded)}
      >
        <UilBars />
      </div>
      <motion.div
        className="sidebar"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        <div className="logosss">
          <span>
            Ki<span>shk</span>inda
          </span>
        </div>

        <div className="menu">
          <div
            className={`menuItem dashboard actives`}
            onClick={handleClickDash}
          >
            <div className="">Annoucement</div>
          </div>
          <div className={`menuItem w-full actives`} onClick={handleClickDash2}>
            Discount
          </div>
          <div className={`menuItem actives`} onClick={handleClickDash3}>
            <div>Gallery</div>
          </div>
          {/* <div className="menuItem flex flex-col">
                        {
                            click === true && <Link className="text-center dashboard" onClick={() => setActive(3)}>Nature of Business</Link>
                        }{
                            click === true && <Link className="text-center dashboard" onClick={() => setActive(4)}>Registeration Type</Link>
                        }
                    </div> */}
          <div className="menuItem" onClick={handleClick}>
            <UilSignOutAlt /> Logout
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
