import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const ResortAmenities = () => {
  return (
    <div className='about_us_content'>
    <Container fluid>
      <Row>
        <Col md={6} className="order-md-1">
          <h2>Resort Amenities</h2>
          <p>
          SUITES & COTTAGES: Resort having 4 Luxurious AC Deluxe Suite Room, (Building) 12 Ac & 13 NON-AC individual cottages with well-equipped furniture, CTV, Telephone, attached bathroom with Hot & cold Water. There are Mountain View cottages, Garden view cottage, Pool view cottage.
          </p>
        </Col>
        <Col md={6} className="order-md-2">
          <img
            src="Images/IMAGE/Kiskinda_Heritage_Resort_Im1.jpg"  // Replace with your image URL
            alt="Resort Amenities"
            className="img-fluid"
          />
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default ResortAmenities;
